// imported fonts
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");
// import local font from asssets/atomsfer.ttf
@font-face {
  font-family: "Atomsfer";
  src: url("./context/assets/Atomosfer.ttf");
}

// define color
$my_pink: rgb(255, 34, 144);
$my_orange: rgb(255, 55, 0);

body {
  // fix borders
  margin: 0;
  padding: 0;
}

.App {
  height: 100vh;
  width: 100vw;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  // color
  background-color: rgb(63, 63, 63);

  .topbar {
    // placement
    position: fixed;
    top: 0;
    left: 0;

    // sizing
    height: 10vh;
    width: 100vw;

    // text
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    color: white;

    // alignment
    display: flex;
    align-items: center;

    // padding, margin
    padding-left: 2rem;
    padding-top: 10px;

    .background-container {
      // sizing
      border-radius: 10px;
      background-color: $my_pink;
      border: 3px solid $my_pink;
      width: 350px;
      height: 85px;
      background-color: rgb(40, 40, 40);
      box-shadow: inset -2px -2px 4px 5px rgba(0, 0, 0, 0.833);
      .container {
        width: inherit;
        height: inherit;
        border-radius: inherit;

        display: flex;
        align-items: center;
        justify-content: center;

        margin-top: 18px;
        margin-left: 18px;
        box-shadow: -3px -3px 4px 5px rgba(0, 0, 0, 0.211);
        background-color: rgb(40, 40, 40);
        border: 3px solid $my_orange;

        &::hover {
          margin-top: 0;
          margin-left: 0;
        }

        .org-title {
          color: white;
          font-weight: 100;
          font-family: Atomsfer;
          font-size: 2.5rem;
          .inc {
            color: $my_pink;
            font-family: "Open Sans", sans-serif;
            font-weight: 600;
            font-size: 1rem;
          }
        }
      }
    }
  }

  .dashboard {
    // placement
    display: flex;

    // sizing
    height: 65vh;
    width: 80vw;

    // color
    background-color: rgb(40, 40, 40);
    border: 3px solid $my_orange;

    box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.211);
    border-radius: 20px;
  }
}

.criteria-bar {
  padding: 15px;
  display: flex;
  flex-direction: column;
  min-width: 100px;
  .input-criteria {
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: rgb(28, 28, 28);
    box-shadow: inset -2px -2px 4px 5px rgba(0, 0, 0, 0.323);
    border: 2px solid rgb(66, 66, 66);
    border-radius: 10px;

    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    color: rgb(251, 182, 142);
    outline: none;
    margin: 5px;
  }

  /* This styles the scrollbar track */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
    height: 8px; /* Height of the horizontal scrollbar */
  }

  /* This styles the thumb (the draggable part of the scrollbar) */
  &::-webkit-scrollbar-thumb {
    background: gray; /* Color of the scrollbar itself */
    border-radius: 4px; /* Roundness of the scrollbar */
  }

  /* This is optional: styles the thumb when it's being hovered or clicked */
  &::-webkit-scrollbar-thumb:hover {
    background: darkgray; /* Darker color on hover */
  }

  overflow-y: auto;
}

.search {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;

  font-family: "Open Sans", sans-serif;
  display: flex;
  align-items: center;

  font-size: 1.2rem;
  color: rgb(200, 200, 200);
  background-color: transparent;
  border: 2px solid $my_pink;
  outline: none;
  margin: 5px;
  transition: 0.3s;
  &:hover {
    background-color: $my_pink;
    color: white;
    cursor: pointer;
  }
}
.phos {
  font-family: Atomsfer;
}
.organization {
  height: 40px;
}

.employees {
  flex-grow: 1;
  padding: 15px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  margin-right: 40px;

  /* This styles the scrollbar track */
  &::-webkit-scrollbar {
    width: 8px; /* Width of the vertical scrollbar */
    height: 8px; /* Height of the horizontal scrollbar */
  }

  /* This styles the thumb (the draggable part of the scrollbar) */
  &::-webkit-scrollbar-thumb {
    background: gray; /* Color of the scrollbar itself */
    border-radius: 4px; /* Roundness of the scrollbar */
  }

  /* This is optional: styles the thumb when it's being hovered or clicked */
  &::-webkit-scrollbar-thumb:hover {
    background: darkgray; /* Darker color on hover */
  }

  .employee {
    border-radius: 10px;
    min-height: 60px;
    margin: 2.5px;
    background-color: rgb(28, 28, 28);
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid $my_pink;

    // space between items
    div {
      flex: 1;
      font-family: "Open Sans", sans-serif;
      font-size: 1.1rem;
      color: rgb(193, 193, 193);
      margin-left: 20px;
      margin-right: 20px;
    }
  }
  .graph {
    border-radius: 10px;
    height: 45px;
    margin: 2.5px;
    padding-left: 15px;
    padding-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // space between items
    div {
      flex: 1;
      font-family: "Open Sans", sans-serif;
      font-size: 1.2rem;
      color: rgb(193, 193, 193);
      margin-left: 20px;
      margin-right: 20px;
    }
  }
}

.popup {
  background-color: rgb(19, 19, 19);
  border: 2px solid $my_pink;
  color: white;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  .text {
    font-family: "Open Sans", sans-serif;
    font-size: 1.5rem;
    color: rgb(193, 193, 193);
    text-align: center;
    margin-bottom: 20px;
  }
  .close {
    font-family: "Open Sans", sans-serif;
    font-size: 1.2rem;
    color: rgb(193, 193, 193);
    background-color: transparent;
    border: 2px solid $my_pink;
    outline: none;
    margin: 5px;
    padding: 5px;
    border-radius: 5px;
    &:hover {
      background-color: rgb(28, 28, 28);
    }
  }
}
